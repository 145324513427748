const routes = [
    { path: '/' },
    { path: '/about' },
    { path: '/digital' },
    { path: '/oohmedia' },
    { path: '/atlmedia' },
    { path: '/btlmedia' },
    { path: '/transitmedia' },
    { path: '/tvcmedia' },
    { path: '/event' },
    { path: '/filmproduction' },
    { path: '/offsetdesigning' },
    { path: '/corporategifting' },
    { path: '/contact' },
    { path: '/blog' }

];

module.exports = routes; 
