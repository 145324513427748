import React, { useEffect } from 'react';
import Navbar from './Components/Navbar/Navbar';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import Home from './Components/Home/Home';

import Footer from './Components/Footer/Footer';
import About from './Components/About/About';
import Gototop from './Components/Gototop/Gototop';
import { FloatingWhatsApp } from 'react-floating-whatsapp';
import Logo from './Assets/Logo.png'
import ScrollToTop from './Components/ScrollToTop';
import Contact from './Components/Contact/Contact';
import DigitalMedia from './Components/Solutions/DigitalMedia/DigitalMedia';
import OohMedia from './Components/Solutions/OohMedia/OohMedia';
import Alt from './Components/Solutions/Alt/Alt';
import Transit from './Components/Solutions/Transit/Transit';
import Tvc from './Components/Solutions/Tvc/Tvc';
import Event from './Components/Solutions/Event/Event'
import Film from './Components/Solutions/Film/Film';
import Offsetdesigning from './Components/Solutions/Offsetdesign/Offsetdesign';
import Corporate from './Components/Solutions/Corporate/Corporate';
import Blt from './Components/Solutions/Blt/Blt';
import Blog from './Components/Blog/Blog';
import routes from './routes'

const PageTracker = () => {
  const location = useLocation();

  useEffect(() => {
    if (window.gtag) {
      window.gtag('config', 'G-LQCE6S6RPG', {
        page_path: location.pathname + location.search,
      });
      console.log("Page view tracked: ", location.pathname + location.search);
    }
  }, [location]);

  return null;
};



const App = () => {
  const components = {
    '/': Home,
    '/about': About,
    '/digital': DigitalMedia,
    '/oohmedia': OohMedia,
    '/atlmedia': Alt,
    '/btlmedia': Blt,
    '/transitmedia': Transit,
    '/tvcmedia': Tvc,
    '/event': Event,
    '/filmproduction': Film,
    '/offsetdesigning': Offsetdesigning,
    '/corporategifting': Corporate,
    '/contact': Contact,
    '/blog': Blog,
    
  };


  return (
    <>
     
      
        <BrowserRouter basename='/' > {/* basename="/Yeskondo" */}
        <ScrollToTop />
          <Navbar />
          <PageTracker/>
          <Routes>
          
            {routes.map((route) => (
              <Route key={route.path} path={route.path} element={React.createElement(components[route.path])} />
            ))}
   
        </Routes>
          <Gototop/>  
          <FloatingWhatsApp phoneNumber="+919545797960" accountName="Yeskondo" avatar={Logo} darkMode={false} />
          <Footer/>
        </BrowserRouter>
     
    </>
  );
};

export default App;
